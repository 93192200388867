import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Link} from "gatsby";

const ProductsIndexPage = () => (
  <Layout sectionClasses={"t-products"} dataNamespace={"products"}>
    <SEO title="PRODUCTS" />
    <div className="t-products__ttl p-title">
      <span className="t-products__ttl__main">PRODUCTS</span><span className="t-products__ttl__sub">HOTARU SERIES</span>
    </div>
    <div className="t-products__detail">
      <div className="t-products__detail__content">
        <div className="t-products__detail__content__left">
          <Link to="/about/lm/">
            <picture>
              <source media="(max-width:959px)" srcSet={"/images/top/pc/products_01.jpg"} />
              <img className="" src={"/images/top/pc/products_01.jpg"} alt="" />
            </picture>
            <h3>ローション・モイスチャライザーセット</h3>
            <h4>LOTION&MOISTURIZER SET</h4>
          </Link>
        </div>
        <div className="t-products__detail__content__center">
          <Link to="/about/cleansing/">
            <picture>
              <source media="(max-width:959px)" srcSet={"/images/top/pc/products_05.jpg"} />
              <img className="" src={"/images/top/pc/products_05.jpg"} alt="" />
            </picture>
            <h3>クレンジング</h3>
            <h4>CLEANSING</h4>
          </Link>
        </div>
        <div className="t-products__detail__content__right">
          <Link to="/about/treatmentmask/">
            <picture>
              <source media="(max-width:959px)" srcSet={"/images/top/pc/products_04.jpg?v=202201251400"} />
              <img className="" src={"/images/top/pc/products_04.jpg?v=202201251400"} alt="" />
            </picture>
            <h3>トリートメントマスク</h3>
            <h4>TREATMENT MASK</h4>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProductsIndexPage
